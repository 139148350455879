import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { GoogleMap, LoadScript } from '@react-google-maps/api'
import { Marker } from '@react-google-maps/api'
import { Link } from 'gatsby'
import axios from 'axios'
import MarketoForm from './marketoForm'

const supportedFormats = ['text/plain', 'application/rtf', 'application/msword', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Name must be 2 characters at minimum')
    .required('Name is required'),
  email: Yup.string()
    .email('Invalid email address format')
    .required('Email is required'),
  country: Yup.string()
    .min(2, 'Name must be 2 characters at minimum')
    .required('Country is required'),
  message: Yup.string()
    .min(5, 'Message must be 5 characters at minimum')
    .required('Message is required'),
  subscribe: Yup.bool().oneOf([true], 'Tick if you wish to receive communications'),
})

//const url = 'https://script.google.com/macros/s/AKfycbxiDs6B3Xf0iB59poq2qIqVy8pL1gotO7AURj5RS4PNIybT-qL04AD5vag4XxRthuan/exec'
const url = 'https://script.google.com/macros/s/AKfycbwKWvz96A_huX08lhpomRjFs0hqriQqZ2IX8rLNfjzi5sF3BUUfCz84fMSPMDSKTD3R/exec'
const formUrl = 'https://script.google.com/macros/s/AKfycbzlc6e8-dKZidcMuo74EfCBBYFxMLeLPWDgE5irglZoi_rz-5vn_R5QGOa3qqeZCIk6/exec'
const uploadUrl = 'https://script.google.com/macros/s/AKfycbyDE6mPRqPm3pv7esdy2L8pHH_V3HrM0NSezXXmj5-wuu-pGZLvl24ddv5PIeI65Lgb6g/exec'

const marketoScriptId = 'mktoForms'
const marketoScript2Id = 'mktoForms2'
const formId = '1775'


class ContactMain extends Component {
  constructor(props, ...args) {
    super(props, ...args)
    this.state = {
      callback: 'not fired',
      value: null,
      pageUrl: null, 
      load: false,
      expired: 'false',
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ load: true, pageUrl: "" })
    })
    this.handleScriptLoad()
  }

  asyncScriptOnLoad = () => {
    this.setState({ callback: 'called!' })
  }


  handleScriptLoad = () => {
    let allsuspects=document.getElementsByTagName("script");
    for (let i=allsuspects.length; i>=0; i--){
    if (allsuspects[i] && allsuspects[i].getAttribute("id")!==null 
      && allsuspects[i].getAttribute("id").indexOf(marketoScriptId) !== -1 ){
             allsuspects[i].parentNode.removeChild(allsuspects[i])
        }    
    }

    // include marketo form library
    var s = document.createElement('script');
    s.id = marketoScriptId;
    s.type = 'text/javascript';
    s.async = true;
    s.src = '//go.unicomengineering.com/js/forms2/js/forms2.min.js';
    document.getElementsByTagName('head')[0].appendChild(s);
  }

  handleScriptSubmit = (values) => {
    let allsuspects=document.getElementsByTagName("script");
    for (let i=allsuspects.length; i>=0; i--){
    if (allsuspects[i] && allsuspects[i].getAttribute("id")!==null 
      && allsuspects[i].getAttribute("id").indexOf(marketoScript2Id) !== -1 ){
             allsuspects[i].parentNode.removeChild(allsuspects[i])
        }    
    }

    // script to handle marketo form submission
      var formEl = document.createElement('FORM')
      formEl.id = 'mktoForm_' + formId
      // formEl.style.display = 'none'
      document.body.appendChild(formEl)

    
    window.MktoForms2.loadForm('//go.unicomengineering.com', '221-VVO-216', formId)
    setTimeout( function(){
      if( typeof  window.MktoForms2 != "undefined" ) {
           window.MktoForms2.whenReady( function (form) {  
        // form.setValues = ({
        //     "FirstName":values.name,
        //     "Email":values.email,
        //     "Company":values.subscribe,
        //     "Country":values.country,
        //     "commentCapture":values.message
        //     })
            form.setValues = ({
              "FirstName":"Anne Other",
              "Email":"anne.other@testing123.com",
              "Company":"ANOther, Inc.",
              "Country":"UK",
              "commentCapture":"Testing - 2022.12.14 16:38"
            })
          // }, function(k, v){var o={};o[k]=v;form.addHiddenFields(o)});
          console.log(form.vals())
        form.submit()
        form.onSuccess(function(values, followUpUrl) {
          form.getFormElem().hide()
          return false
        })
      })
    }
      }, 2000 )
      
  

}

  render() {
    const { value, callback, load, expired } = this.state || {}

    return (
      <MDBContainer>
        <MDBRow>
          <MDBCol md="6" className="md-0 mb-5">
            <Formik
              initialValues={{
                name: "",
                email: "",
                country: "",
                message: "",
                resume: "", 
                subscribe: false,
              }}
              validationSchema={validationSchema}
              /* onSubmit={({ setSubmitting }) => { */
              onSubmit={(values, { setSubmitting, resetForm }) => {
                /* alert('Form is validated! Submitting the form...') */
                let formData = {
                  PageUrl: this.state.pageUrl,
                  Name: values.name,
                  Email: values.email,
                  Country: values.country,
                  Message: values.message,
                  Subscribe: values.subscribe,
                }

                this.handleScriptSubmit(values)
                
                try {
                    const response = axios({                  
                        method: 'get',
                        mode: 'no-cors', 
                        proxy: 'https://script.google.com', 
                        url: `${url}?timestamp=&url=&name=${encodeURIComponent(values.name)}&email=${encodeURIComponent(values.email)}&country=${encodeURIComponent(values.country)}&message=${encodeURIComponent(values.message)}&subscribe=${encodeURIComponent(values.subscribe)}`
                        })
                        .then((response) => {
                            console.log(response)
                        }).catch((e) => {
                            console.log(e)
                        })
                        this.setState({values: '', expired: 'true'})
                        resetForm()
                        //alert("Submitted.  Thank you!")
                      } catch (e) {
                        console.log(`Axios request failed: ${e}`)
                      }
                    setSubmitting(false)
                  }}
            >
              {({ touched, errors, values, isSubmitting }) => (
                <Form
                  name="contact"
                  method="post"
                  action=""
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                >
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value="contact" />
                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 font-w-600 text-medium">
                        <label htmlFor="name">
                          Name <span style={{ color: "red" }}>*</span>
                        </label>
                        <Field
                          type="name"
                          name="name"
                          aria-label="name"
                          value={values.name || ""}
                          placeholder="Your full name"
                          className={`form-control ${
                            touched.name && errors.name ? "is-invalid" : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="name"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="6">
                      <div className="mb-4 font-w-600 text-medium">
                        <label htmlFor="country">
                          Country <span style={{ color: "red" }}>*</span>
                        </label>
                        <Field
                          type="text"
                          name="country"
                          aria-label="country"
                          value={values.country || ""}
                          placeholder="Your country of residence"
                          className={`form-control ${
                            touched.country && errors.country
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="country"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                    <MDBCol md="6">
                      <div className="mb-4 font-w-600 text-medium">
                        <label htmlFor="email">
                          Email <span style={{ color: "red" }}>*</span>
                        </label>
                        <Field
                          type="email"
                          name="email"
                          aria-label="email"
                          value={values.email || ""}
                          placeholder="Your email address"
                          className={`form-control ${
                            touched.email && errors.email ? "is-invalid" : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="email"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 font-w-600 text-medium">
                        <label htmlFor="message">Message</label>
                        <Field
                          type="textarea"
                          component="textarea"
                          name="message"
                          aria-label="message"
                          value={values.message || ""}
                          placeholder="Enter message"
                          className={`form-control ${
                            touched.message && errors.message
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="message"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  {/* <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 font-w-600 text-medium">
                        <label htmlFor="resume">Upload resume</label>
                        <Field
                          type="file"
                          name="resume"
                          aria-label="resume"
                          //value={values.resume || ""}
                          //value={this.state.resume || ""} 
                          //onChange={this.handleChange}
                          onBlur={e => {
                            // handleChange(e)
                            this.setState({
                              selectedFile: e.currentTarget.files[0],
                              loaded: 0
                            })    
                            // console.log(e.currentTarget.files[0])
                            // console.log(e.currentTarget.value)
                          }}
                          placeholder="Upload your resume"
                          className={`form-control ${
                            touched.resume && errors.resume ? "is-invalid" : ""
                          }`}
                        />
                        {errors.resume && <p className="is-invalid">{errors.resume}</p>}
                      </div>
                    </MDBCol>
                  </MDBRow> */}

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mt-3 mb-4 grey-text font-w-400 text-small">
                        To receive occasional updates about products, services,
                        news and events, please tick the box below. You can
                        unsubscribe at any time.
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="form-group form-check grey-text font-w-400 text-small mb-3">
                        <Field
                          type="checkbox"
                          id="subscribe"
                          name="subscribe"
                          aria-label="subscribe"
                          className={`form-check-input ${
                            touched.subscribe && errors.subscribe
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <label htmlFor="subscribe">
                          Yes, I would like to receive communications.
                        </label>
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-400 text-small">
                        For details on how your data is stored and used, please
                        visit our{" "}
                        <Link to="/page-2" className="effect">
                          Privacy Statement
                        </Link>
                        .
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12" className="mb-4">
                      <div style={{ paddingTop: "1rem" }}>
                        <button
                          type="submit"
                          className="mt-4 btn btn-medium btn-unicom"
                          style={{ display: "block" }}
                          disabled={isSubmitting ? true : false}
                        >
                          {isSubmitting ? "Please wait..." : "Submit"}
                        </button>
                      </div>
                    </MDBCol>
                  </MDBRow>
                </Form>
              )}
            </Formik>

            <MarketoForm formId="1779" />
          </MDBCol>

          <MDBCol md="6" className="contact-address">
            <p className="font-w-400 text-medium mt-2 ml-3 mb-5">
              Company Name
              <br />
              Street
              <br />
              Town
              <br />
              Country
              <br />
              <br />
              T: Tel number
              <br />
              F: Fax number
              <br />
              E:{" "}
              <a href="mailto:info@helloworld.com" className="effect">
                info@helloworld.com
              </a>
            </p>
            <MDBCol md="12" className="mt-4 mb-3">
              <div
                id="map-container"
                className="rounded z-depth-1-half map-container"
                style={{ height: "400px" }}
              >
                <LoadScript
                  id="script-loader"
                  googleMapsApiKey="AIzaSyCpXjW8NbD3JZOylLfE3vYEBj1spgmovhU"
                >
                  <GoogleMap
                    id="example-map"
                    mapContainerStyle={{
                      height: "400px",
                      width: "100%",
                    }}
                    zoom={15}
                    center={{
                      lat: 36.272079,
                      lng: -117.470734,
                    }}
                  >
                    <Marker
                      onLoad={marker => {}}
                      position={{
                        lat: 36.272079,
                        lng: -117.470734,
                        title: "Hello World HQ",
                      }}
                    />
                  </GoogleMap>
                </LoadScript>
              </div>
            </MDBCol>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    )
  }
}

export default ContactMain