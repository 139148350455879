import React from "react"
import Layout from "../components/layout"
import SEO from "../components/SEO"
//import Contact from "../components/formGS"
import Contact from "../components/contactSimple"

export default () => (
  <Layout>
    <SEO title="JTF starter site page-css" description="Not much here!" />
    <div className="content">
      <h1>Contact Us</h1>
      <p>Please let us know how we can be of assistance.</p>
      <Contact />
    </div>
  </Layout>
)